<template>
	<div>
		<table-div :datas="datas" :header="header" :tdwidths="[58, 11, 11, 20]">
			<template #action="props">
				<template v-if="user.auth_guest">
					<button
						class="btn btn-outline-secondary"
						@click="readView(props.item)"
						:title="props.item.name"
					>
						聆聽
					</button>
				</template>
			</template>
		</table-div>
		<modal v-if="showModal" @closeModal="showModal = false">
			<template #header>
				<h4 class="modal-title">觀看 {{ playing }}</h4>
			</template>
			<template #body>
				<div class="video-wrap">
					<player :options="videoOptions" :currentTime="currentTime"> </player>
					<!--<video controls>
						<source :src="currentSrc" />
					</video>-->
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { mediaResourceRest, mediaResourceAction } from "@/api/ebookSystem.js";
import tableDiv from "@/components/tableDiv.vue";
import modal from "@/components/popup/modal.vue";
import player from "@/components/live/videoPlayer.vue";

export default {
	components: {
		modal,
		tableDiv,
		player
	},
	data() {
		return {
			datas: [],
			header: {
				name: "名稱",
				author: "作者",
				duration: "時長",
				count: "瀏覽量",
				action: "動作"
			},
			showModal: false,
			videoOptions: {
				autoplay: true,
				controls: true,
				sources: [
					{
						src: "",
						type: "video/mp4"
					}
				]
			},
			currentSrc: "",
			currentTime: 0
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			mediaResourceRest
				.list()
				.then(res => {
					this.datas = [];
					res.data.forEach((v, i) => {
						this.datas.push({ ...v, action: v });
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		readView(item) {
			this.playing = item.name;
			this.videoOptions.sources[0].src = mediaResourceAction.content(item.id, {
				name: item.name
			});
			// console.log(this.videoOptions.sources[0].src);
			this.currentSrc = item.url;
			this.showModal = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.video-wrap {
	width: 44vw;
	@media screen and (max-width: 767px) {
		width: auto;
	}
}
</style>
